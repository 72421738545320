import { combineReducers } from '@reduxjs/toolkit';
import { reducer as formReducer } from 'redux-form';
import auth from './accountReducer';

const reducers = combineReducers({
  form: formReducer,
  auth: auth,
});

export default reducers;
