export const getlocalStorage = (key) => {
    if (typeof window !== 'undefined') {
        try {
            const item = localStorage.getItem(key);
            return item ? JSON.parse(item) : null

        } catch (error) {
            console.error('Error setting localStorage:', error);
            return null
        }
    }
}

export const setlocalStorage = (key, value) => {
    if (typeof window !== 'undefined') {
        try {
            localStorage.setItem(key, JSON.stringify(value))

        } catch (error) {
            console.error('Error setting localStorage:', error);
        }
    }
}

export const removeLocalStorageItem = (key) => {
    if (typeof window !== 'undefined') {
        try {
            localStorage.removeItem(key);
        } catch (error) {
            console.error('Error removing localStorage item:', error);
        }
    }
};